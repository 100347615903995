export default {
  en: {
    buttons: {
      sign_in: 'Sign In',
      sign_in_with_google: 'Sign in with Google',
      request: 'Request',
      next: 'Next',
      finish: 'Finish',
      done: 'Great!',
      save: 'Save',
      confirm: 'Confirm',
      cancel: 'Cancel',
      enable_authenticator: 'Enable Authenticator',
      authenticate: 'Authenticate',
      start_first_review: 'Complete Supplier Self-Assessment',
      resume_first_review: 'Resume Supplier Self-Assessment',
      start_review: 'Start full review now',
      change_password: 'Change Password',
      configure_2fa: 'Configure 2FA',
      back: 'Back',
      continue: 'Continue',
    },
    links: {
      forgot_password: 'Forgotten Password',
      sign_up: 'Sign Up',
      sign_in: 'Sign In',
      resend_confirm: 'Resend confirmation code',
      problems: 'Problems logging in? Contact us',
      skip_authenticator: 'No thanks, I’ll use SMS authentication instead.',
      single_sign_on: 'Sign in with SAML SSO',
      here: 'here',
    },
    placeholders: {
      twoFACode: 'Enter code...',
      emailCode: 'Enter email verification code...',
      email: 'Enter email...',
      password: 'Enter password...',
      confirm_password: 'Confirm password...',
      legal_name: 'My Company Ltd',
      country: 'Select your country...',
      company_no: '0123456789',
      trading_names: 'Press enter after each name...',
      street_address: 'Enter street address...',
      city: 'Enter city...',
      postcode: 'Enter postcode/ZIP Code...',
      industry: 'Select industry/sector...',
      web_address: 'https://example.com',
      business_phone_number: 'Enter business phone number...',
      first_name: 'Enter first name...',
      last_name: 'Enter last name...',
      mobile: 'Enter mobile number...',
      verification_code: 'Enter verification code...',
      team: 'Select team...',
      jobTitle: 'Enter job title...',
    },
    titles: {
      forgot_password: 'Forgotten Password',
      reset_password: 'Reset password for',
      welcome: 'Welcome to Risk Ledger!',
      org_reg: 'Organisation Registration',
      org_lead_reg: 'Organisation Lead User Registration',
      org_agreement: 'Organisational Agreements',
      user_agreement: 'User Agreements',
      user_notification_agreement: 'Email Notifications',
      confirm_signup: 'Confirm your email address',
      setup_2fa: 'Setup Two Factor Authentication',
      registered_address: 'Registered Office Address',
    },
    labels: {
      i_agree: 'Yes, I agree',
      receive_email_notifications: 'Receive Notification Emails',
      reset_password_help: 'Check your email inbox for your reset code.',
      new_password: 'New Password',
      confirm_password: 'Confirm Password',
      twoFACode: 'Code',
      emailCode: 'Email Code',
      legal_name: 'Company Legal Name',
      country: 'Country',
      company_no: 'Company Registration Number',
      trading_names: 'Trading Names',
      street_address: 'Street Address',
      city: 'City',
      postcode: 'Postcode/ZIP Code',
      industry: 'Industry/Sector',
      web_address: 'Web Address',
      business_phone_number: 'Business Phone Number',
      mobile: 'Mobile Number',
      email: 'Email',
      name: 'Name',
      password: 'Password',
      team: 'Team',
      jobTitle: 'Job Title',
      or: 'or',
    },
    help: {
      welcome: 'Welcome!',
      legal_name: 'Insert the legal name your organisation is registered under.',
      country: 'Select the country your organisation is legally registered in.',
      company_no: 'Your legally registered company identification number, if applicable.',
      trading_names:
        'Insert any other names that your company is known by, pressing enter after each.',
      street_address: 'Insert your company’s registered address.',
      web_address: 'Insert your company’s website address.',
      business_phone_number: 'The number we can use to contact your company.',
      phone: 'We will be using this number for recovery purposes',
      team: 'Please select the team that best describes your role.',
      requiredRef: '* Required',
      passwordMeetsRequirements: 'Meets requirements',
      passwordDoesNotMeetRequirements: 'Does not meet requirements.',
      passwordRequirements: `The password must be minimum 8 characters and contain at least:\n* 1 uppercase letter\n* 1 lowercase letter\n* 1 number\n* one of the following special characters:\n = + - ^ {'$'} * . [ ] {'{'} {'}'} ( ) ? " ! {'@'} # % & / \\ , > < ' : ; {'|'} _ ~ \``,
    },
    errors: {
      API_ERROR: 'There was an error performing that request',
      network_error:
        'Unable to connect. Please check your connectivity or try a different network.',
      org_exists_title:
        'Your organisation already has an account on Risk Ledger, so you won’t need to create another one.',
      org_exists_subtitle:
        ' Reach out to {email} or {intercom} to get access rather than create a new account.',
      user_exists_title:
        'A user with this email already has an account on Risk Ledger, so you won’t need to create another one.',
      user_exists_subtitle:
        ' Reach out to {email} or {intercom} to get access rather than create a new account.',
      inviteRedeemed:
        'This invite was sent to multiple contacts in your organisation, and has already been accepted by a colleague. Reach out to {email} or {intercom} to get access rather than create a new account.',
      clickHere: 'click here',
      not_authorized_exception: 'Your session has expired. Please re-authenticate.',
      invalid_code_exception: 'Your code is invalid. Please re-authenticate for a new code.',
      no_google_oauth_url:
        'Failed to start Google Sign-In. Please try again or contact our support team.',
      networkWarning:
        "We've detected a problem between our servers and your browser which will affect your ability to sign up.",
    },
    validation: {
      required: 'Please enter valid information',
      password: 'The password must meet the requirements',
      phone: 'Please enter a valid telephone number',
      select: 'Please select an option',
      email: 'Please enter a valid email address',
      toggle: 'You must agree to this',
      tag: 'Please enter at least 1 tag',
      textarea: 'Please enter some text',
      password_match: 'Passwords must match',
    },
    copy: {
      org_signup_1_1:
        'To get started, please let us know the details of the organisation you are registering.',
      org_signup_1_2: `For help at any point please contact us at <a href="mailto: support{'@'}riskledger.com" target="_blank">support{'@'}riskledger.com</a>, chat to us on Intercom, or visit our <a href="https://help.riskledger.com" target="_blank">help centre</a>.`,
      org_signup_already_user: 'Already registered? Sign in here.',
      org_signup_2_1:
        'Please enter your details below. You can add as many users as you need to complete your profile once you have created your account.',
      org_signup_2_2:
        'You can find out more on our <a href="https://help.riskledger.com" target="_blank">help centre</a>.',
      org_signup_invite: '{0} has invited you to join Risk Ledger!',
      org_signup_invite_accepted: 'This invite from {0} has already been accepted.',
      org_signup_invite_expired:
        'This invite from {0} has expired, but you can still sign up below.',
      org_signup_invite_cancelled:
        '{0} has cancelled this invite, but you can still sign up below.',
      user_signup_1_2: 'Please complete your registration below to get started.',
      org_agreement: 'Do you agree, on behalf of {0} ',
      org_agreement_2:
        'to our <a href="https://www.riskledger.com/terms.pdf" target="_blank">Terms of Service</a> for organisations?',
      user_agreement:
        'Do you agree to our <a href="https://www.riskledger.com/terms.pdf" target="_blank">Terms of Service</a> and <a href="https://www.riskledger.com/privacy" target="_blank">Privacy Policy</a> for users?',
      user_notification_agreement:
        "We send notifications to keep you up to date with important events related to your organisation's security and assurance. We recommend receiving these alerts, but you can always turn them off in your settings later.",
      contact_agreement: 'Do you agree to receive marketing emails from Risk Ledger?',
      confirm_resent: 'Email confirmation code resent',
      emailVerification:
        'To verify the email address that you’ve provided, we’ve emailed you a confirmation code. Please enter this below.\n\n' +
        'This can sometimes go through to your junk folder, but if you’re having issues receiving this, please reach out to {email} or {intercom} to open the chat.',
      enter_confirm: 'The verification code has been sent to {0}',
      enter_sms: 'Please enter the code from the SMS that has just been sent to you.',
      enter_authenticator: 'Please enter the code from the authenticator app on your device.',
      setup_2fa:
        'Two factor authentication (2FA) is mandatory for all users on Risk Ledger. We recommend using an authenticator app to generate codes which can be setup in just two steps. Authentication apps are more secure and don’t rely on mobile service to generate codes.',
      authenticator_step_1: 'Scan the QR code using your authenticator app or use the Secret Key',
      authenticator_step_2: 'Enter the newly generated code below:',
      scan_or_copy_secret: 'Scan the QR or copy the Secret Key below:',
      supplier: 'Supplier',
      client: 'Client',
      twofa_is_authenticator_1: 'You currently have 2FA configured with an authenticator app.',
      twofa_is_authenticator_2: 'If you prefer to use SMS instead, you can switch to it below.',
      twofa_is_sms_1: 'You currently have 2FA configured with SMS.',
      twofa_is_sms_2: 'We recommend using an authenticator app, which you can switch to below.',
      action_add_user_title: 'Add Users',
      action_add_user_description:
        'You can add and remove team members at any time! To start with, we recommend that you add the people within your organisation responsible for information security, HR, and IT.',
      action_assessment_title: 'Complete Assessment',
      action_assessment_description:
        'Complete our scoping questions followed by the main assessment. Our scoping questions allow us to answer groups of questions on your behalf, saving you time.',
      action_actions_title: 'Check Your Actions',
      action_actions_description:
        'Keep an eye out for any actions that your Clients may request of you in your Action Centre. Actions typically involve providing more evidence to support an answer, or implementing a security control that you haven’t yet implemented.',
      getting_started: 'Getting Started',
      getting_started_intro:
        'Welcome to Risk Ledger! We want to help you manage and increase your security maturity, and make responding to requests for security assurance quick and easy.',
      getting_started_learn_more: 'Learn more about Risk Ledger',
      getting_started_questions: 'Got a question? Check the help section or chat to us.',
      verification_success: 'Verification successful',
      verification_send_error: 'Error sending verification code',
      verification_send_success: 'Verification code sent.',
      verification_notification: 'You have unverified contact details',
      verify_email: 'Verify Email',
      verify_phone_number: 'Verify Phone Number',
      resend_validation_email: 'Resend verification email',
      resend_validation_sms: 'Resend verification SMS',
      supplier_client_choice_title: 'Get started as a Supplier or Client?',
      supplier_client_choice_description:
        'Please select whether you want to get started as a supplier or client on the Risk Ledger platform.',
      supplier_description:
        'As a supplier, you can manage your security, complete your assessment and share with your clients.',
      client_description:
        'As a client, you can run a security assurance programme over your suppliers. Our free tier covers up to 5 suppliers, please get in touch for more details.',
      become_supplier_success: 'You are now a supplier',
      become_client_success: 'You are now a client',
      phone_input_1:
        'We want to ensure your information is protected, so we enforce Two-factor Authentication across all of our users.\nYou can find out more about our Two-factor Authentication {mfalink}.\n\n' +
        'Please provide a valid mobile phone number that your one-time access codes can be sent to.\nYou can change your method of authentication once logged in.',
      implicitTerms:
        'By creating an account, you agree to our <a href="https://www.riskledger.com/terms.pdf" target="_blank">Terms of Service</a> and <a href="https://www.riskledger.com/privacy" target="_blank">Privacy Policy</a>.',
    },
    teams: {
      sales: 'Sales',
      security: 'Security',
      compliance: 'Compliance',
      procurement: 'Procurement',
      legal: 'Legal',
      esg: 'ESG',
      it: 'IT',
      other: 'Other',
    },
  },
};
