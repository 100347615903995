import yescompliantwithexpirydatei18n from './yescompliantwithexpirydate/i18n';
import yescomplianti18n from './yescompliant/i18n';
import nocomplianti18n from './nocompliant/i18n';
import atleasti18n from './atleast/i18n';
import atmaxi18n from './atmax/i18n';
import multiselectatmaxi18n from './multiselectatmax/i18n';

export default {
  en: {
    yescompliant: yescomplianti18n.en,
    yescompliantwithexpirydate: yescompliantwithexpirydatei18n.en,
    nocompliant: nocomplianti18n.en,
    atleast: atleasti18n.en,
    atmax: atmaxi18n.en,
    multiselectatmax: multiselectatmaxi18n.en,
  },
};
