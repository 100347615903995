export default {
  en: {
    title: 'Dashboard',
    emergingThreat: {
      title: 'New Emerging Threat - {name}',
      text: "We've added {name} to Risk Ledger so that you may assess the risk of {name} in your supply chain. Your suppliers will now begin adding information about their response to this threat, available to you in your Emerging Threat dashboard.",
      cta: 'View Dashboard',
    },
    metricCard: {
      error: 'Unable to load metric',
    },
    metrics: {
      averageCompliance: 'Average Compliance',
      suppliersAwaitingReview: 'Suppliers Awaiting Review',
      totalSuppliers: 'Total Suppliers',
      pendingInvitesRequests: 'Pending Invites & Requests',
      pendingRemediations: 'Pending Remediations',
      incompleteAssessments: 'Incomplete Assessments',
      openRisks: 'Open Risks',
      discussionResponses:
        '<b>{n} discussion</b> is currently active. | <b>{n} discussions</b> are currently active.',
      remediationResponses:
        '<b>{n} remediation response</b> is awaiting your review. | <b>{n} remediation responses</b> are awaiting your review.',
      suppliersNoPoliciesApplied:
        '<b>{n} supplier</b> has no policies applied. |<b>{n} suppliers</b> have no policies applied.',
      recentlyExpiredInvites:
        '<b>{n} invite</b> recently expired without being accepted. | <b>{n} invites</b> recently expired without being accepted.',
      pendingReceivedConnectionRequests:
        '<b>{n} connection request</b> from a supplier is pending your response. | <b>{n} connection requests</b> from your suppliers are pending a response.',
      approvalsExpired: '<b>{n} approval</b> has expired. | <b>{n} approvals</b> have expired.',
      approvalsExpiringSoon:
        '<b>{n} approval</b> will be expiring soon. | <b>{n} approvals</b> will be expiring soon.',
    },
    help: {
      averageCompliance: 'The average compliance of all your suppliers with completed assessments.',
      suppliersAwaitingReview:
        'The number of suppliers with a completed assessment that are currently unapproved.',
      totalSuppliers: 'The total number of suppliers you are connected with.',
      pendingInvitesRequests:
        'The number of supplier invites and connection requests you have sent that are still pending.',
      pendingRemediations: 'The number of active remediation requests awaiting response or review.',
      incompleteAssessments: 'The number of suppliers with an incomplete assessment.',
      openRisks: 'The number of risks you currently have open across all of your suppliers.',
    },
    delta: {
      positive: '+{0}',
      negative: '{0}',
      noChange: 'No change',
      thirtyDays: 'in the last {0} days',
    },
    routeText: {
      discussionResponses: 'View active discussions',
      remediationResponses: 'View remediation requests',
      suppliersNoPoliciesApplied: 'View suppliers',
      recentlyExpiredInvites: 'View and resend invites',
      pendingReceivedConnectionRequests: 'View received connection requests',
      approvalsExpiringSoon: 'View approvals expiring soon',
      approvalsExpired: 'View expired approvals',
    },
    breakdowns: {
      suppliersAwaitingReview: {
        unapproved: 'Unapproved',
        inreview: 'In Review',
        approved: 'Approved',
        rejected: 'Rejected',
        pending_biz: 'Pending Business',
        expired: 'Expired',
      },
      pendingInvitesRequests: {
        pendingInvites: 'Pending Invites',
        pendingSentConnectionRequests: 'Pending Sent Connection Requests',
      },
      pendingRemediations: {
        pendingClientReview: 'Pending Your Review',
        pendingSupplierRemediation: 'Pending Supplier Remediation',
      },
      criticality: {
        unknown: 'Unknown',
        minor: 'Minor',
        critical: 'Critical',
        important: 'Important',
      },
      openRisks: {
        open: 'Open',
        closed: 'Closed',
      },
    },
    buttons: {
      add: 'Add',
    },
  },
};
