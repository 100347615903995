<script lang="ts" setup>
import type { IconColour } from '@/components/base/_BaseIcon.vue';

export type LabelSize = 'small' | 'medium' | 'large';
export type LabelColour = 'default' | 'primary' | 'alert' | 'warning' | 'info' | 'navy' | 'purple';

withDefaults(
  defineProps<{
    colour?: LabelColour;
    size?: LabelSize;
    closeable?: boolean;
    filled?: boolean;
    icon?: string;
    iconColour?: IconColour;
    hexColour?: string;
    disabled?: boolean;
  }>(),
  {
    colour: 'default',
    size: 'small',
    filled: true,
    icon: undefined,
    iconColour: undefined,
    hexColour: undefined,
  },
);

const emit = defineEmits<{
  close: [];
}>();
</script>

<template>
  <span
    class="label"
    :style="[
      {
        backgroundColor: filled ? hexColour || '' : '',
        borderColor: hexColour || '',
        color: filled ? '' : hexColour || '',
      },
    ]"
    :class="[
      {
        'px-9 py-3 text-sm': size === 'small',
        'px-12 py-6 text-base': size === 'medium',
        'px-15 py-9 text-lg': size === 'large',
      },
      colour,
      { 'pointer-events-none opacity-30': disabled },
      { filled: filled },
    ]"
  >
    <span v-if="icon" class="mr-5 flex items-center">
      <BaseIcon class="leading-none" :size="size" :name="icon" :colour="iconColour" />
    </span>
    <span class="flex flex-1 items-center"><slot /></span>
    <span v-if="closeable" class="ml-5 flex cursor-pointer items-center opacity-80">
      <BaseIcon
        :size="size"
        name="close"
        class="leading-none"
        data-testid="label-close"
        @click="emit('close')"
      />
    </span>
  </span>
</template>

<style scoped>
.label {
  box-sizing: border-box;
  display: inline-flex;
  white-space: nowrap;
  border-style: solid;
  border-width: 1px;
  border-radius: theme('borderRadius.full');
  box-shadow: theme('boxShadow.sm');

  &.default {
    color: theme('colors.default');
    background-color: transparent;
    border-color: theme('borderColor.neutral');

    &.filled {
      background-color: theme('colors.navy-100');
    }
  }

  &.primary {
    color: theme('colors.primary');
    background-color: transparent;
    border-color: theme('colors.primary');

    &.filled {
      color: theme('colors.primary');
      background-color: theme('backgroundColor.primary');
      border-color: theme('backgroundColor.primary');
    }
  }

  &.alert {
    color: theme('colors.error');
    background-color: transparent;
    border-color: theme('colors.error');

    &.filled {
      color: theme('colors.error');
      background-color: theme('backgroundColor.error');
      border-color: theme('backgroundColor.error');
    }
  }

  &.warning {
    color: theme('colors.text-warning');
    background-color: transparent;
    border-color: theme('colors.warning');

    &.filled {
      color: theme('colors.text-warning');
      background-color: theme('backgroundColor.warning');
      border-color: theme('backgroundColor.warning');
    }
  }

  &.info {
    color: theme('colors.info');
    background-color: transparent;
    border-color: theme('colors.info');

    &.filled {
      color: theme('colors.info');
      background-color: theme('backgroundColor.info');
      border-color: theme('backgroundColor.info');
    }
  }

  &.navy {
    color: theme('colors.navy-500');
    background-color: transparent;
    border-color: theme('colors.navy-500');

    &.filled {
      color: theme('colors.navy-200');
      background-color: theme('colors.navy-500');
      border-color: theme('colors.navy-500');
    }
  }

  &.purple {
    color: theme('colors.purple-600');
    background-color: transparent;
    border-color: theme('colors.purple-600');

    &.filled {
      color: theme('colors.purple-600');
      background-color: theme('colors.purple-50');
      border-color: theme('colors.purple-50');
    }
  }
}
</style>
