import { AxiosError } from 'axios';
import { jwtDecode } from 'jwt-decode';
import cognitoAuth from '@/legacy/auth/cognito';
import api from './api';

export const REFRESH_TOKEN = 'sso.refreshToken';
export const ACCESS_TOKEN = 'sso.accessToken';

let cachedAccessToken: string | null;
let cachedRefreshToken: string | null;

export function setTokens(refreshToken: string, accessToken: string) {
  window.localStorage.setItem(REFRESH_TOKEN, refreshToken);
  window.localStorage.setItem(ACCESS_TOKEN, accessToken);

  cachedAccessToken = accessToken;
  cachedRefreshToken = refreshToken;
}

export function clearTokens() {
  // eslint-disable-next-line no-console
  console.log('clearTokens called');
  window.localStorage.removeItem('authMode');
  window.localStorage.removeItem(REFRESH_TOKEN);
  window.localStorage.removeItem(ACCESS_TOKEN);

  cachedAccessToken = null;
  cachedRefreshToken = null;
}

async function refreshAccessToken() {
  // If for some reason the refresh token was saved to localStorage when it was null.
  // It will later be retrieved as a string 'null'.
  // This check catches that case and avoids the API request.
  if (!cachedRefreshToken || cachedRefreshToken === 'null') {
    throw new Error('No refresh token to refresh access token');
  }

  // API request to refresh the accessToken
  try {
    const response = await api.refreshAccessToken(cachedRefreshToken);
    setTokens(cachedRefreshToken, response.data.accessToken);
    return response.data.accessToken;
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // Successful request but bad response, assume expired refresh token
      clearTokens();
    }

    // Returning null will cause the response interceptor to reload the page
    // if a 401 response is returned.
    throw new Error('Error while refreshing access token');
  }
}

function isTokenValid(accessToken: string) {
  try {
    const decodedToken = jwtDecode(accessToken) as any;
    const expiredMillisecondsTimestamp = decodedToken.exp * 1000;
    const now = Date.now();

    if (now > expiredMillisecondsTimestamp) {
      // Token is expired
      throw new Error('Expired access token');
    }
    return true;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error) {
    return false;
  }
}

export async function getToken() {
  if (cachedAccessToken && isTokenValid(cachedAccessToken)) {
    return cachedAccessToken;
  }
  const token = await refreshAccessToken();
  return token;
}

export async function isAuthenticated() {
  if (cachedAccessToken === undefined) {
    cachedAccessToken = window.localStorage.getItem(ACCESS_TOKEN);
    cachedRefreshToken = window.localStorage.getItem(REFRESH_TOKEN);
  }

  const token = await getToken();
  if (!token) {
    return false;
  }
  return true;
}

export async function logout() {
  clearTokens();
  await cognitoAuth.logout();
}

export async function authenticateSSO(ssoCallbackCode: string) {
  // Exchange code for access and refresh token
  const ssoCallbackRes = await api.ssoCallback(ssoCallbackCode);

  const ssoAccessToken = ssoCallbackRes.data.accessToken;
  const ssoRefreshToken = ssoCallbackRes.data.refreshToken;

  // Set localStorage mode and SSO tokens
  if (!ssoRefreshToken || !ssoAccessToken) {
    throw new Error('Invalid SSO tokens');
  }
  setTokens(ssoRefreshToken, ssoAccessToken);
}

export async function authenticateOAuthSSO(ssoCallbackCode: string) {
  // Exchange code for access and refresh token
  const ssoCallbackRes = await api.ssoOAuthCallback(ssoCallbackCode);

  const ssoAccessToken = ssoCallbackRes.data.accessToken;
  const ssoRefreshToken = ssoCallbackRes.data.refreshToken;

  // Set localStorage mode and SSO tokens
  if (!ssoRefreshToken || !ssoAccessToken) {
    throw new Error('Invalid SSO tokens');
  }
  setTokens(ssoRefreshToken, ssoAccessToken);
}
