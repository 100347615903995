import { request } from '@/api/request';
import type { Control } from '@/types/models/control';
import type { Domain } from '@/types/models/domain';

export type GetFrameworkResponse = {
  controls: Control[];
  domains: Domain[];
};

const getFramework = () => request<GetFrameworkResponse>('GET', '/framework');

export type FrameworkConfig = {
  addOnDomains: string[];
};

const getFrameworkDefaultConfig = () =>
  request<FrameworkConfig>('GET', '/framework/config/default');

const setFrameworkDefaultConfig = (config: FrameworkConfig) =>
  request<FrameworkConfig>('PUT', '/framework/config/default', config);

const api = {
  getFramework,
  getFrameworkDefaultConfig,
  setFrameworkDefaultConfig,
};
export default api;

export const replaceAPI = (newApi) => {
  Object.keys(newApi).forEach((i) => {
    api[i] = newApi[i];
  });
};
