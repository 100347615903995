// Get object of domainID mapped to number of answers
const numAnswersByDomain = (state, getters, rootState, rootGetters) => {
  const controlsByDomain = rootGetters['framework/controlsByDomain'];
  return Object.keys(controlsByDomain).reduce((obj, domainID) => {
    obj[domainID] = controlsByDomain[domainID].reduce((i, c) => {
      const a = state.controlAnswers[c]
        ? state.controlAnswers[c].answer || state.controlAnswers[c].notApplicable
        : null;
      return a ? i + 1 : i;
    }, 0);
    return obj;
  }, {});
};

// Get object of domainID mapped to number of confirmed answers
const numConfirmedAnswersByDomain = (state, getters, rootState, rootGetters) => {
  const { controls } = state.reassessmentStatus;
  const controlsByDomain = rootGetters['framework/controlsByDomain'];
  return Object.keys(controlsByDomain).reduce((obj, domainID) => {
    obj[domainID] = controlsByDomain[domainID].reduce((i, c) => {
      if (!controls) return i;
      let confirmed = false;
      const reassessmentControl = controls[c];
      if (reassessmentControl) confirmed = reassessmentControl.confirmed;
      const a = state.controlAnswers[c];
      return a && (confirmed || a.notApplicable) ? i + 1 : i;
    }, 0);
    return obj;
  }, {});
};

// number of applicable answers cofirmed in a domain
const numConfirmedApplicableAnswersByDomain = (state, getters, rootState, rootGetters) => {
  const { controls } = state.reassessmentStatus;
  const controlsByDomain = rootGetters['framework/controlsByDomain'];
  return Object.keys(controlsByDomain).reduce((obj, domainID) => {
    obj[domainID] = controlsByDomain[domainID].reduce((i, c) => {
      if (!controls) return i;
      let confirmed = false;
      const reassessmentControl = controls[c];
      if (reassessmentControl) confirmed = reassessmentControl.confirmed;
      const a = state.controlAnswers[c];
      return a && confirmed && !a.notApplicable ? i + 1 : i;
    }, 0);
    return obj;
  }, {});
};

// Get object of each domainID mapped to a list of controlIDs that must still be confirmed in reassessment.
// To be used while reassessment is in progress.
// Deprecated controls should not appear, although non-deprecated controls in deprecated domains might
// if they are confirmable.
const confirmableControlsByDomain = (state, getters, rootState, rootGetters) => {
  const controls = state.reassessmentStatus ? state.reassessmentStatus.controls : null;
  const controlsByDomain = rootGetters['framework/controlsByDomain']; // excludes deprecated controls
  return Object.keys(controlsByDomain).reduce((obj, domainID) => {
    // if no reassessment status then real state cannot be determined, so disallow confirmations for this domain
    if (!controls) {
      obj[domainID] = [];
      return obj;
    }

    // controls in out-of-scope domains cannot be confirmed
    if (getters.outOfScope[domainID]) {
      obj[domainID] = [];
      return obj;
    }

    // determine confirmability for each control in this domain
    obj[domainID] = controlsByDomain[domainID].reduce((confirmableControlIDs, controlID) => {
      // unanswered (or non-applicable) control cannot be confirmed
      const answer = state.controlAnswers[controlID];
      if (!answer || !answer.answer || answer.notApplicable) return confirmableControlIDs;

      // applicable answer uses confirmed status (or default) to determine if can be confirmed
      let confirmed = false;
      const reassessmentControl = controls[controlID];
      if (reassessmentControl) confirmed = reassessmentControl.confirmed;
      if (!confirmed) confirmableControlIDs.push(controlID);

      return confirmableControlIDs;
    }, []);
    return obj;
  }, {});
};

// Get percentage of controls that have been answered by domain
// Use to lookup the percentage of controls answered by domain
// Ignores deprecated controls in percentage
const percentageDomainAnswered = (state, getters, rootState, rootGetters) => (domainID) => {
  const controlsByDomain = rootGetters['framework/controlsByDomain'];
  const controls = controlsByDomain[domainID] ? controlsByDomain[domainID].length : 0;
  const answers = getters.numAnswersByDomain[domainID];
  return Math.floor((answers / controls) * 100);
};

// Get percentage of controls that have been answered by domain
// Use to lookup the percentage of controls answered by domain
// Ignores deprecated controls in percentage
const percentageDomainConfirmed = (state, getters, rootState, rootGetters) => (domainID) => {
  const controlsByDomain = rootGetters['framework/controlsByDomain'];
  const controls = controlsByDomain[domainID] ? controlsByDomain[domainID].length : 0;
  const answersConfirmed = getters.numConfirmedAnswersByDomain[domainID];
  return Math.floor((answersConfirmed / controls) * 100);
};

// Get percentage of controls that have been confirmed in a domain.
// It defaults to 0 if all confirmed controls are not applicable.
const effectivePercentageDomainConfirmed = (state, getters) => (domainID) => {
  const applicableAnswersConfirmed = getters.numConfirmedApplicableAnswersByDomain[domainID];
  return applicableAnswersConfirmed > 0 ? getters.percentageDomainConfirmed(domainID) : 0;
};

const totalScopingQuestions = (state, getters, rootState, rootGetters) => {
  // Get sortedDomains
  const sortedDomains = rootGetters['framework/sortedDomains'];

  // Filter domains by those that have a scoping question
  const scopingQuestions = sortedDomains.filter((domain) => domain.scopingQuestion);
  const totalScopingQuestions = scopingQuestions.length;

  return totalScopingQuestions;
};

const totalScopingAnswered = (state, getters, rootState, rootGetters) => {
  // Get sortedDomains
  const sortedDomains = rootGetters['framework/sortedDomains'];
  const scopingQuestions = sortedDomains.filter((domain) => domain.scopingQuestion);

  const scopingAnswered = scopingQuestions.reduce((res, question) => {
    if (state.scopingAnswers[question.domainID]) {
      res[question.domainID] = state.scopingAnswers[question.domainID];
    }
    return res;
  }, []);
  const totalScopingAnswered = Object.keys(scopingAnswered).length;
  return totalScopingAnswered;
};

// Get the percentage of scoping that have been answered
const percentageScopingAnswered = (state, getters) => () =>
  Math.floor((getters.totalScopingAnswered / getters.totalScopingQuestions) * 100);

// Use to lookup whether a domainID is out of scope.
// Returns map of domainID to true if out of scope, or false if in scope.
// If domain does not have a scoping answer, it is in scope.
const outOfScope = (state, getters, rootState) =>
  rootState.framework.domains.reduce((res, domain) => {
    res[domain.domainID] = state.scopingAnswers?.[domain.domainID]?.answer === false;
    return res;
  }, {});

const isScopingComplete = (state) => (state.status ? state.status.scopingComplete : false);

const isAssessmentComplete = (state) =>
  state.status ? state.status.initialAssessmentComplete : false;

const isReassessing = (state) =>
  state.reassessmentStatus && state.reassessmentStatus.status === 'reassessment_inprogress';

// Get sorted deprecated controls that have been answered.
// Returns a list of controlIDs.
const sortedControlAnswersDeprecated = (state, _getters, _rootState, rootGetters) => {
  const controlAnswersIDs = Object.keys(state.controlAnswers);
  const sortedControlsDeprecated = rootGetters['framework/sortedControlsDeprecated'].reduce(
    (res, controlObj) => {
      res.push(controlObj.controlID);
      return res;
    },
    [],
  );
  return sortedControlsDeprecated.filter((value) => controlAnswersIDs.includes(value));
};

const controlsRequiringAttention = (state, getters, rootState, rootGetters) => {
  const sortedControls = rootGetters['framework/sortedControls'];
  const answers = state.controlAnswers;
  const outOfScope = getters.outOfScope;
  const isReassessing = getters.isReassessing;
  const domainsByID = rootGetters['framework/domainsByID'];
  const scopingAnswers = state.scopingAnswers;

  return sortedControls
    .filter((control) => {
      // Ignore deprecated and out of scope
      if (control.deprecated || outOfScope[control.domainID]) return false;

      // Add controls that changed meaning
      const answer = answers[control.controlID];
      if (answer && !answer.notApplicable && answer.unconfirmedControlUpdate) {
        return true;
      }

      // If reassessing then also add unanswered applicable controls (whose scoping question was answered)
      if (isReassessing) {
        if (!domainsByID[control.domainID].scopingQuestion || scopingAnswers[control.domainID]) {
          if (!answer || (!answer.answer && !answer.notApplicable)) {
            return true;
          }
        }
      }

      return false;
    })
    .map((control) => control.controlID);
};

const scopingQuestionsRequiringAttention = (state, getters, rootState, rootGetters) => {
  const sortedDomains = rootGetters['framework/sortedDomains'];

  return sortedDomains
    .filter(
      (domain) =>
        !domain.deprecated && domain.scopingQuestion && !state.scopingAnswers[domain.domainID],
    )
    .map((domain) => domain.domainID);
};

const totalNumberRequiringAttention = (state, getters) =>
  getters.controlsRequiringAttention.length + getters.scopingQuestionsRequiringAttention.length;

// Get sorted domains that have controls or scoping questions requiring attention.
// When reassessing it also returns domains that have unconfirmed controls.
// When not reassessing it also returns domains that have unanswered controls.
// Returns a list of domain objects.
const sortedIncompleteDomains = (state, getters, rootState, rootGetters) => {
  const sortedDomains = rootGetters['framework/sortedDomains'];
  const domainsByID = rootGetters['framework/domainsByID'];
  const outOfScope = getters.outOfScope;

  // get domains from controls requiring attention
  const domainsFromControls = getters.controlsRequiringAttention.map((controlID) => {
    const control = rootGetters['framework/controlsByID'][controlID];
    return domainsByID[control.domainID];
  });

  // get domains from scoping questions requiring attention
  const domainsFromScopingQuestions = sortedDomains.filter(
    (domain) =>
      !domain.deprecated && domain.scopingQuestion && !state.scopingAnswers[domain.domainID],
  );

  // get non 100% answered domains
  const nonCompleteDomains = sortedDomains.filter(
    (domain) =>
      !domain.deprecated &&
      !outOfScope[domain.domainID] &&
      getters.percentageDomainAnswered(domain.domainID) < 100,
  );

  // get domains from confirmable controls (will be empty if not reassessing)
  const domainsFromUnconfirmedControls = getters.confirmableControlsByDomain;
  const filteredDomainsFromUnconfirmedControls = Object.keys(domainsFromUnconfirmedControls).reduce(
    (res, key) => {
      if (domainsFromUnconfirmedControls[key].length > 0) {
        res.push(domainsByID[key]);
      }
      return res;
    },
    [],
  );

  // combine and remove duplicates based on if we are reassessing or not
  const filteredDomains = [
    ...new Set([
      ...domainsFromControls,
      ...domainsFromScopingQuestions,
      ...nonCompleteDomains,
      ...(getters.isReassessing ? filteredDomainsFromUnconfirmedControls : []),
    ]),
  ];

  // Sort filtered domains by letter
  filteredDomains.sort((a, b) => {
    if (a.letter < b.letter) {
      return -1;
    }
    if (a.letter > b.letter) {
      return 1;
    }

    return 0;
  });

  return filteredDomains;
};

export const getters = {
  numAnswersByDomain,
  numConfirmedAnswersByDomain,
  numConfirmedApplicableAnswersByDomain,
  percentageDomainAnswered,
  percentageDomainConfirmed,
  effectivePercentageDomainConfirmed,
  percentageScopingAnswered,
  outOfScope,
  isScopingComplete,
  isAssessmentComplete,
  sortedControlAnswersDeprecated,
  isReassessing,
  confirmableControlsByDomain,
  totalScopingQuestions,
  totalScopingAnswered,
  controlsRequiringAttention,
  scopingQuestionsRequiringAttention,
  totalNumberRequiringAttention,
  sortedIncompleteDomains,
};
