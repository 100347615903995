// DO NOT IMPORT i18n FROM THE MODULE'S INDEX AS THIS CAUSES CIRCULAR DEPENDENCIES
import { createI18n } from 'vue-i18n';
import answerSearchi18n from '@/modules/answerSearch/i18n';
import components from '@/components/i18n/en';
import authi18n from '@/modules/auth/i18n';
import approvali18n from '@/modules/approval/i18n';
import assessment from '@/modules/assessment/i18n/en';
import profile from '@/modules/profile/i18n/en';
import actionCentre from '@/modules/actionCentre/i18n';
import labels from '@/modules/labels/i18n/en';
import quickConnecti18n from '@/modules/quickConnect/i18n';
import searchi18n from '@/modules/search/i18n';
import mode from '@/modules/mode/i18n/en';
import invite from '@/modules/invite/i18n/en';
import policy from '@/modules/policy/i18n/en';
import global from '@/i18n/global/en';
import activityFeedi18n from '@/modules/activityFeed/i18n';
import answeri18n from '@/modules/answer/i18n';
import discussionsi18n from '@/modules/discussions/i18n/en';
import discussionsInActivityFeedi18n from '@/modules/discussionsInActivityFeed/i18n';
import orgInfoi18n from '@/modules/orgInfo/i18n';
import logini18n from '@/modules/login/i18n';
import authSSOLogini18n from '@/modules/authSSOLogin/i18n';
import authSSOSettingsi18n from '@/modules/authSSOSettings/i18n';
import clientNotesi18n from '@/modules/clientNotes/i18n';
import notificationSettingsi18n from '@/modules/notificationSettings/i18n';
import clientReportingi18n from '@/modules/clientReporting/i18n';
import clientNetworki18n from '@/modules/clientNetwork/i18n';
import settingsi18n from '@/modules/settings/i18n';
import connectionRequesti18n from '@/modules/connectionRequest/i18n';
import welcomei18n from '@/modules/welcome/i18n';
import profileSharingLinksi18n from '@/modules/profileSharingLinks/i18n';
import profileSharingPublici18n from '@/modules/profileSharingPublic/i18n';
import businessApprovalPublici18n from '@/modules/businessApprovalPublic/i18n';
import clientDashboardi18n from '@/modules/clientDashboard/i18n';
import networkGraphi18n from '@/modules/networkGraph/i18n';
import connectionViewSuppliersi18n from '@/modules/connectionViewSuppliers/i18n';
import connectionViewClientsi18n from '@/modules/connectionViewClients/i18n';
import filtersi18n from '@/modules/filters/i18n';
import zeusi18n from '@/modules/zeus/i18n';
import zeusEmergingThreatsi18n from '@/modules/zeusEmergingThreats/i18n';
import zeusTrialsi18n from '@/modules/zeusTrials/i18n';
import uploadsi18n from '@/modules/uploads/i18n';
import filePreviewi18n from '@/modules/filePreview/i18n';
import apiPublici18n from '@/modules/apiPublic/i18n';
import emergingThreatsSuppliersi18n from '@/modules/emergingThreatsSuppliers/i18n';
import emergingThreatsCommoni18n from '@/modules/emergingThreatsCommon/i18n';
import emergingThreatsClientsi18n from '@/modules/emergingThreatsClients/i18n';
import customPropertiesi18n from '@/modules/customProperties/i18n';
import triali18n from '@/modules/trial/i18n';
import risksi18n from '@/modules/risks/i18n';
import routes from '@/routes/i18n';
import salesMockNetworkGraphi18n from '@/modules/salesMockNetworkGraph/i18n';
import federatedInvitesi18n from '@/modules/federatedInvites/i18n';
import federatedDashboardi18n from '@/modules/federatedDashboard/i18n';
import controlTypei18n from '@/modules/controlType/i18n';
import connectionViewSubEntitiesi18n from '@/modules/connectionViewSubEntities/i18n';
import federatedNetworki18n from '@/modules/federatedNetwork/i18n';
import federatedSubEntitiesProfilei18n from '@/modules/federatedSubEntitiesProfile/i18n';
import documentsi18n from '@/modules/documents/i18n';
import messages from '@/legacy/i18n/messages';
import insightsi18n from '@/modules/insights/i18n';
import rolesi18n from '@/modules/roles/i18n';
import errorsi18n from '@/modules/errors/i18n';
import zeusCommunitiesi18n from '@/modules/zeusCommunities/i18n';
import communitiesi18n from '@/modules/communities/i18n';
import communitiesMessageBoardi18n from '@/modules/communitiesMessageBoard/i18n';

// DO NOT IMPORT i18n FROM THE MODULE'S INDEX AS THIS CAUSES CIRCULAR DEPENDENCIES

export const i18n = createI18n({
  allowComposition: true,
  locale: 'en',
  warnHtmlInMessage: 'off',
  messages: {
    en: {
      ...messages.en,
      global: global.en,
      components: components.en,
      auth: authi18n.en,
      approval: approvali18n.en,
      assessment: assessment.en,
      clientDashboard: clientDashboardi18n.en,
      profile: profile.en,
      actionCentre: actionCentre.en,
      labelsModule: labels.en,
      quickConnect: quickConnecti18n.en,
      search: searchi18n.en,
      connectionRequest: connectionRequesti18n.en,
      invite: invite.en,
      mode: mode.en,
      policy: policy.en,
      activityFeed: activityFeedi18n.en,
      answer: answeri18n.en,
      discussions: discussionsi18n.en,
      discussionsInActivityFeed: discussionsInActivityFeedi18n.en,
      orgInfo: orgInfoi18n.en,
      routes: routes.en,
      login: logini18n.en,
      authSSOLogin: authSSOLogini18n.en,
      authSSOSettings: authSSOSettingsi18n.en,
      clientNotes: clientNotesi18n.en,
      notificationSettings: notificationSettingsi18n.en,
      clientReporting: clientReportingi18n.en,
      clientNetwork: clientNetworki18n.en,
      settings: settingsi18n.en,
      welcome: welcomei18n.en,
      profileSharingLinks: profileSharingLinksi18n.en,
      profileSharingPublic: profileSharingPublici18n.en,
      businessApprovalPublic: businessApprovalPublici18n.en,
      controlType: controlTypei18n.en,
      networkGraph: networkGraphi18n.en,
      connectionViewSuppliers: connectionViewSuppliersi18n.en,
      connectionViewClients: connectionViewClientsi18n.en,
      filters: filtersi18n.en,
      uploads: uploadsi18n.en,
      filePreview: filePreviewi18n.en,
      apiPublic: apiPublici18n.en,
      zeus: zeusi18n.en,
      zeusEmergingThreats: zeusEmergingThreatsi18n.en,
      zeusTrials: zeusTrialsi18n.en,
      emergingThreatsSuppliers: emergingThreatsSuppliersi18n.en,
      emergingThreatsClients: emergingThreatsClientsi18n.en,
      emergingThreatsCommon: emergingThreatsCommoni18n.en,
      customProperties: customPropertiesi18n.en,
      trial: triali18n.en,
      risks: risksi18n.en,
      salesMockNetworkGraph: salesMockNetworkGraphi18n.en,
      connectionViewSubEntities: connectionViewSubEntitiesi18n.en,
      federatedInvites: federatedInvitesi18n.en,
      federatedDashboard: federatedDashboardi18n.en,
      federatedNetwork: federatedNetworki18n.en,
      federatedSubEntitiesProfile: federatedSubEntitiesProfilei18n.en,
      documents: documentsi18n.en,
      insights: insightsi18n.en,
      roles: rolesi18n.en,
      errorScreens: errorsi18n.en,
      zeusCommunities: zeusCommunitiesi18n.en,
      communities: communitiesi18n.en,
      communitiesMessageBoard: communitiesMessageBoardi18n.en,
      answerSearch: answerSearchi18n.en,
    },
  },
});
