export default {
  en: {
    unclaimedSupplier: 'Unclaimed',
    addSupplier: 'Add Supplier',
    answer: {
      answered: 'Answered',
      unanswered: 'Unanswered',
      answeredClient: 'You answered',
      last_modified: 'Last modified {0}',
    },
    info: {
      country: 'Country',
      companyNumber: 'Company Number',
      notProvided: 'Not Provided',
      sector: 'Sector',
      tradingNames: 'Trading Names',
      unclaimed: 'This information hasn’t been verified by the Supplier',
      answersSelected: '{count} answer selected | {count} answers selected',
      incompleteAssessment: 'This supplier has not yet completed their assessment.',
      incompleteAssessmentClient: 'It looks like you have not yet completed your assessment.',
      incompleteAssessmentNote: 'You will be notified when they do so.',
      incompleteAssessmentNoteClient: 'You will be able to see your compliance when you do so.',
      noFilteredAnswers: 'No answers match the filters applied',
      noFilteredAnswersNote: 'Change the filters to show some answers',
      lastUpdatedTooltip: 'The last time this supplier updated an answer.',
      lastUpdatedAt: 'Last updated: {date}',
      unclaimedNotConnectedTitle: 'No one from this Supplier is active on Risk Ledger yet',
      unclaimedNotConnectedDesc:
        'They will be included in your list of suppliers but you may miss some data',
      claimedNotConnectedTitle:
        'Add this Supplier to request access to their assessment and add notes, risks and documents',
      claimedNotConnectedDesc: "We'll let you know once the Supplier has accepted your request",
      unverified: 'Unclaimed',
      addsupplier: 'Add Supplier',
    },
    stats: {
      compliance: 'Compliance',
      score: 'Score',
      controls: 'Controls',
      compliant: 'Compliant',
      nonComplianceApplied: 'Non-compliant',
      remediations: 'Remediations',
      remediationRequests: 'Requests',
      remediationResponses: 'Responses',
      risks: 'Risks',
      complete: 'Complete',
      openRisks: 'Open',
      closedRisks: 'Closed',
      myReview: 'Compliance Overview',
      networkSignals: 'Community Signals',
      approvalRating: 'Approval Rating',
      allCommunities: 'all your communities',
      controlSignals: 'Signal on controls | Signals on controls',
      risksAndSignals: 'Risks & Signals',
      openRisksIn: 'Open risk | Open risks',
      closedRisksIn: 'Closed risk | Closed risks',
      communityApproved: '{x} out of {y} peer in {community} | {x} out of {y} peers in {community}',
      communityApprovedPercentage: '{percentage}%',
      approvalRate: 'Approval rate',
      communityConcentrationRisk: 'Potential Concentration Risk',
      communityConcentrationRiskDescription: 'Connections',
      viewHere: 'View here',
      new: 'New',
    },
    policy: {
      required: 'Policy requires',
      notRequired: 'Control not required',
      yes: 'Yes',
      no: 'No',
      orMore: '{0} or more',
      orLess: '{0} or less',
    },
    buttons: {
      cancel: 'Cancel',
      confirm: 'Confirm',
      editAnswer: 'Edit Answer',
      answer: 'Answer',
      actions: 'Actions',
    },
    tags: {
      criticality: 'Criticality',
      confidentiality: 'Confidentiality',
      pii: 'PII',
      set_tag_failed: 'Failed to set tag',
    },
    overrideReason: {
      nonComplianceTitle: 'Non-compliance Applied',
      exemptionAppliedTitle: 'Exemption Applied',
      nonComplianceDesc:
        'Applying non-compliance will override the compliance status of this answer, making it non-compliant until removed.',
      exemptionDesc:
        'Applying exemption will override the compliance status of this answer, making it compliant until removed.',
      nonComplianceDescMulti:
        'Applying non-compliance will override the compliance status of these answers, making them non-compliant until removed.',
      exemptionDescMulti:
        'Applying exemptions will override the compliance status of these answers, making them compliant until removed.',
      title: 'Please provide a reason:',
      nonCompliancePlaceholder: 'This answer is non-compliant because...',
      exemptionPlaceholder: 'This answer is compliant because...',
      defaultError: 'Could not apply override, please try again.',
      buttons: {
        applyNonCompliance: 'Apply Non-compliance',
        applyExemption: 'Apply Exemption',
        apply: 'Apply',
        remove: 'Remove',
        applyToSelected: 'Apply to selected',
      },
    },
    overview: {
      status: 'Status',
      client: 'Client',
      policiesApplied: 'Policies Applied',
      policiesAppliedNoneTitle: 'There are no policies being applied to this supplier.',
      policiesAppliedNoneText:
        'This may be because no tags are set above, or because no policies are applied to this supplier’s tags.',
      complianceByDomain: 'Compliance By Domain',
      errorClient: 'Failed to load client. Please try again.',
      errorSupplier: 'Failed to load supplier. Please try again.',
      clientBanner: "You're currently viewing your compliance against {name}'s policies.",
      assignEmpty: 'Nobody yet.',
      assignYourself: 'Assign yourself',
      assignUsers: 'Select Users...',
      cancel: 'Cancel',
      save: 'Save',
      assignedTo: 'Assigned to',
      errorAssignment: 'There was an error assigning users',
      assessmentStatus: 'Assessment Status',
      openRisk: 'Open Risk',
      noPoliciesApplied1: `{org} have not yet applied their policies to your profile..`,
      noPoliciesApplied2: `Once policies have been applied, you'll be able to see a breakdown of your compliance by domain and question.`,
      noOneActiveTitle: 'No one from this Supplier is active on Risk Ledger yet',
      noOneActiveText: 'They will be included in your list of suppliers but you may miss some data',
      noOneActiveCta: 'Invite to Risk Ledger',
      noOneActivePendingInviteTitle: "We've invited this Supplier to join Risk Ledger",
      noOneActivePendingInviteText:
        'After {email} accepts your invite they will be able to complete their assessment',
      activePendingConnectionRequestTitle: '{name} has requested access to this Supplier',
      activePendingConnectionRequestText:
        'After {email} accepts the request you will be able to access their full profile',
      activeNoConnectionRequestTitle:
        'Add this Supplier to request access to their assessment and add notes, risks and documents',
      activeNoConnectionRequestDesc:
        "We'll let you know once the Supplier has accepted your request",
      basicReview: 'Basic Review',
      privateNotesTitle: 'Private Notes & Files',
      privateNotesCta: 'Add Private Note',
      openRisks: 'No Open Risks | 1 Open Risk | {n} Open Risks',
      requestAccess: 'Request Access',
      errorConnectionPrivacy: 'Failed to update connection privacy, please try again.',
      privateConnectionOffDescription:
        'Your connection to {name} is visible to other organisations on the network and communities.',
      privateConnectionOnDescription:
        'Your connection to {name} is not visible to other organisations on the network and communities.',
      privateConnection: 'Private Connection',
    },
    removeConnection: {
      supplier: 'Remove Supplier',
      client: 'Remove Client',
      message: 'Before continuing please confirm if you would like to remove {name} as a {type}.',
      defaultError: 'Failed to remove connection, please try again.',
      reasonPlaceholder: 'Removal reason...',
      reasonQuestion: 'Why are you removing this connection?',
      messageWarning: 'This message will be sent to {name}.',
    },
    assessment: {
      compliant: 'Compliant',
      nonCompliant: 'Non-Compliant',
      required: 'Required',
      notRequired: 'Not Required',
      openRisks: 'Open Risks',
      noOpenRisks: 'No Open Risks',
      searchControls: 'Search controls and answers...',
      sortBy: 'Sort by...',
      filterBy: 'Filter by...',
      clearFilters: 'Reset',
      domainControl: 'Domain / Control',
      lastModified: 'Last Modified',
      description: 'Description',
      exemptionApplied: 'Exemption Applied',
      nonComplianceApplied: 'Non-Compliance Applied',
      remediationPending: 'Remediation Pending',
      remediationResponded: 'Remediation Responded',
      defaultError: 'Failed to load assessment, please try again.',
      filter: {
        title: 'Keyboard Shortcuts',
        text: 'Use the up and down keys to move between answers.',
      },
      clientBanner: "You're currently viewing your assessment against {name}'s requirements.",
      showOOSControlsTemplate: '{0} out of scope. Show anyway',
      showOOSControls: '{n} controls | {n} control | {n} controls',
      hideOOSControlsTemplate: 'Showing {0} out of scope. Hide',
      hideOOSControls: '{n} controls | {n} control | {n} controls',
      outOfScope: 'Out of Scope',
      notApplicable: 'N/A',
      domainOutOfScope: `Domain is out of scope.<br/>To bring into scope, answer must be yes.`,
      domainInScope: `Domain is in-scope.`,
      deprecated: 'Deprecated',
      nonDeprecated: 'Non Deprecated',
      deprecatedControl: 'This control is deprecated.',
      inprogress: 'In progress - {percent}%',
      overdueBannerTitle: '{0} has not completed a re-assessment within the last 6 months.',
      overdueBannerSubtitle:
        'Their answers may be out of date. You can prompt them to complete their re-assessment by starting a new discussion.',
      openDiscussion: 'Open a Discussion',
      unconfirmedAnswer: {
        title: 'Unconfirmed Answer.',
        description:
          'This control question has been updated since you last reviewed your answer. Please confirm it is still accurate.',
        descriptionClient:
          'This control has been modified since the supplier last reviewed their answer. It may no longer be accurate.',
      },
      noPoliciesApplied: `You're currently awaiting review and {org} have yet to apply their policies to your profile. Once they've been applied, you'll be able to see a breakdown of your compliance by domain and question.`,
      communityRemediations:
        '{n} open remediation in Communities | {n} open remediations in Communities',
      communityRisks: '{n} open risk in Communities | {n} open risks in Communities',
      communityRemediationsFilter: 'Community Remediations',
      communityRisksFilter: 'Community Risks',
    },
    tooltips: {
      approval: 'Approval status',
      evidence: 'Evidence uploaded',
      answer: 'Answer',
      scopingAnswer: 'Scoping Question Answer',
      unanswered: "This control hasn't been answered",
      exemption: 'Exemption applied',
      noncompliance: 'Noncompliance applied',
      remediation: 'Remediation requested',
      remediationResponse: 'Remediation request responded',
      privateNotes: 'Has private notes',
      outOfScope: `This control's domain is out of scope`,
      notApplicable: `This control is not applicable`,
      unconfirmed: 'This control has been updated and is unconfirmed',
      communityControlSignals: 'Community Signals',
    },
    notes: {
      visibleLabel: 'Only visible to {0}',
      placeholder: 'Enter notes about this supplier...',
      title: 'Private Notes',
      save: 'Save',
      defaultError: 'Could not save private note, please try again.',
    },
    remediation: {
      popoverContent: {
        title: 'Please provide a reason and deadline:',
        desc: 'Requesting remediation will send a notification to the supplier, asking them to remediate their answer to this control.',
        button: 'Send Request',
        placeholder: 'This answer needs remediating because...',
        deadlinePlaceholder: 'Deadline',
        defaultError: 'Could not set remediation, please try again.',
      },
      requestButton: 'Request Remediation',
      requested: {
        titleSupplier: 'Remediation Requested',
        titleClient: 'Remediation Requested by Client',
        cancel: 'Cancel',
        deadline: 'Deadline',
        markDone: 'Mark as Done',
      },
      response: {
        titleSupplier: 'Remediated by Supplier',
        titleClient: 'Remediated by You',
        accept: 'Accept',
        rerequest: 'Reject',
      },
    },
    discussion: {
      discussion: 'Discussion',
    },
    tabs: {
      overview: 'Overview',
      assessment: 'Assessment',
      evidence: 'Evidence',
      discussions: 'Discussions',
      activityFeed: 'Activity',
      about: 'About',
      notes: 'Private Notes',
      emergingThreats: 'Emerging Threats',
      risks: 'Risks',
      activityAndDiscussion: 'Activity & Discussion',
    },
    export: {
      downloadAssessment: 'Export Report',
      error: 'Could not download assessment, please try again.',
      asPdf: 'Export as PDF',
      asCsv: 'Export as CSV',
      csv: {
        domainLetter: 'Domain Letter',
        domainName: 'Domain Name',
        controlNumber: 'Control Number',
        controlQuestion: 'Control Question',
        answer: 'Answer',
        answerNotes: 'Answer Notes',
        requirement: 'Requirement',
        compliant: 'Compliant',
        overrideApplied: 'Override Applied',
        clientNotes: 'Client Notes',
        evidence: 'Evidence',
        lastModified: 'Last Modified',
        remediation: 'Remediation',
        no: 'NO',
        yes: 'YES',
        notRequired: 'NOT REQUIRED',
        requires: 'REQUIRES {0}',
        exemption: 'EXEMPTION - {0}',
        nonCompliance: 'NON COMPLIANCE - {0}',
        atMax: '{0} OR LESS',
        atLeast: '{0} OR MORE',
        outOfScope: 'OUT OF SCOPE',
        unanswered: 'UNANSWERED',
        notApplicable: 'NOT APPLICABLE',
        awaitingResponse: 'AWAITING RESPONSE',
        awaitingReview: 'AWAITING REVIEW',
      },
      pdfModal: {
        title: 'Export PDF Report',
        subtitle:
          "Select which fields you would like to include in the report below, and then click 'Export PDF'.",
        cancel: 'Cancel',
        export: 'Export PDF',
        tags: 'Criticality, Confidentiality & PII',
        labels: 'Custom Labels',
        compliance: 'Compliance',
        remediations: 'Remediation Requests',
        requirements: 'Policy Requirements',
        overrides: 'Overrides',
        clientAnswerNotes: 'Client Answer Notes',
        privateNotes: 'Private Notes',
        status: 'Approval Status',
        newPagePerDomain: 'Page Break Between Domains',
        evidence: 'Evidence',
        customProperties: 'Custom Properties',
        risks: 'Risks',
      },
      pdf: {
        title: 'Supplier Assessment Report',
        criticality: 'Criticality',
        confidentiality: 'Confidentiality',
        pii: 'PII',
        labels: 'Labels',
        date: 'Date',
        dateFormat: 'yyyy/MM/dd',
        compliance: 'Compliance',
        remediations: 'Remediations',
        pendingRemediations: '{0} pending',
        status: 'Status',
        scopingQuestion: 'Scoping Question:',
        scopingNotes: 'Scoping Notes:',
        answerResult: 'Answer: {0}',
        yes: 'Yes',
        no: 'No',
        question: 'Question',
        answer: 'Answer',
        requires: 'Requires',
        notRequired: 'Not Required',
        orMore: 'or more',
        orLess: 'or less',
        outOfScope: 'Out of Scope',
        na: 'N/A',
        unanswered: 'Unanswered',
        compliant: 'Compliant',
        nonCompliant: 'Non-compliant',
        numberNonCompliant: '# Non-compliant Controls:',
        answerNotes: 'Answer Notes:',
        clientNotes: 'Client Notes:',
        remediation: 'Remediation:',
        override: 'Override:',
        exemption: 'Exemption Applied: {0}\nCreated On: {1}',
        nonCompliance: 'Non Compliance Applied: {0}\nCreated On: {1}',
        lastModified: 'Last Modified:',
        rl: 'Risk Ledger',
        remediationContent: '{0}\nDeadline: {1}',
        evidence: 'Evidence:',
        approvalExpiresAt: 'Approval Expires',
        reassessmentNextDueAt: 'Reassessment Due',
        exemptions: 'Exemptions Applied',
        nonCompliances: 'Non Compliances Applied',
        privateNotes: 'Private Notes',
        createdAt: 'Date',
        includedEvidence: 'Included Evidence',
        note: 'Note',
        customProperties: 'Custom Properties',
      },
    },
    evidence: {
      entity: 'Evidence',
      downloadAll: 'Download Selected ({count})',
      placeholder: {
        title: 'No evidence uploaded',
        subtitle: 'This organisation has not uploaded any evidence files',
        errorTitle: 'Supplier Evidence',
        errorDescription: 'Failed fetching supplier evidence',
      },
      filter: {
        domainControl: 'Domain / Control',
        lastUploaded: 'Last Uploaded',
        sortBy: 'Sort by...',
        filterBy: 'Filter by...',
        clearFilters: 'Reset',
        searchEvidence: 'Search evidence...',
        noEvidenceMatch: 'No evidence matches the filters applied',
        noEvidenceMatchSub: 'Change the filters to show some evidence',
      },
      filterAttributes: {
        name: 'Name',
        extension: 'File Extension',
        size: 'File Size',
        domain: 'Domain',
      },
      control: 'Control',
      uploaded: 'Uploaded at',
      expiredOn: 'Expired on {date}',
      expiresOn: 'Expires on {date}',
      tableColumns: {
        name: 'Name',
        extension: 'File Extension',
        size: 'File Size',
        uploadedAt: 'Upload Date',
        controlDomain: 'Control / Domain',
        attachments: 'Attachments',
      },
      emergingThreatQuestion: 'Emerging Threat Q.{index}',
    },
    sharedConnections: {
      button: '1 Total Peer | {n} Total Peers',
      title: '{count} Peer | {count} Peers',
      dateAdded: 'Date Connected',
      openRisks: 'Open Risks',
      confidentiality: 'Confidentiality',
      criticality: 'Criticality',
      pii: 'PII',
      yourReview: 'Your Review',
    },
    userLabel: {
      team: 'Team',
      role: 'Role',
      restrictedSupplierAccess: 'They can only access their assigned suppliers.',
      restrictedSupplierAccessSelf: 'You can only access your assigned suppliers.',
    },
    relevantPosts: {
      button: '1 Community Post | {n} Community Posts',
    },
    connect: {
      title: 'Connect with {name}',
      description:
        'To verify your connection request, please include the name and email address of your point of contact at {orgName}:',
      sentTitle: 'Connection Sent',
      sentDescription: "You've requested access to their full profile & assessment.",
      sentContent: '{email} has received an email about your request.',
      nameLabel: 'Contact Name',
      namePlaceholder: 'Jane Doe',
      emailLabel: 'Contact Email Address',
      emailPlaceholder: "jane{'@'}example.com",
      messageLabel: 'Message',
      messagePlaceholder: 'Hi Jane - { currentOrgName } would like to connect with you to...',
      cancel: 'Cancel',
      connect: 'Connect',
      viewBasicProfile: 'View Basic Profile',
      primerEmail: {
        desc: 'I confirm a {primerEmail} has been sent to this supplier.',
        link: 'Primer Email',
      },
      messageAutofillShort:
        'Hi - {senderOrg} would like to connect with you to review your Risk Ledger assessment. Thanks, {senderUser} ({senderEmail})',
      messageAutofillLong:
        'Hi - {senderOrg} would like to connect with you to review your Risk Ledger assessment. We emailed {contact} on [date] letting them know of this request. Thanks, {senderUser} ({senderEmail})',
      addDate: 'Please add the date of contact or modify the message.',
      connectionIDError: 'An error occurred, please refresh the page and try again.',
      categoriseTitle: 'How would you categorise this supplier?',
      categoriseDescription:
        'Categorising your supplier now will make sure that when they fill in their security assessment, they will be assessed against your current policies.',
      finish: 'Finish',
    },
    suppliers: {
      title: 'Add & Invite Supplier',
      name: 'Supplier Name',
      namePlaceholder: 'Supplier Name',
      website: 'Website',
      websitePlaceholder: 'https://example.com',
      companyNumber: 'Registered Company Number',
      companyNumberPlaceholder: 'eg. 10203040',
      industry: 'Sector',
      industryPlaceholder: 'Select sector',
      country: 'Registered Country',
      countryPlaceholder: 'Select Country',
      moreInfo:
        'You can add more information about this supplier if you have it available. This will need to be verified by the supplier and may change after they sign up.',
      contactInfo:
        'If you have a contact at this supplier, we’ll invite them to the Risk Ledger platform to complete an assessment.',
      contactName: 'Contact Name',
      contactNamePlaceholder: 'Jane Doe',
      contactEmail: 'Contact Email Address',
      contactEmailPlaceholder: "jane{'@'}example.com",
      primerEmail: {
        desc: 'I confirm a {primerEmail} has been sent to this supplier.',
        link: 'Primer Email',
      },
      nextStep: 'Next Step',
      addAndInvite: 'Add & Invite Supplier',
      skipInvite: 'Skip Invite For Now',
      step1: 'Set Supplier Information',
      step2: 'Invite to Risk Ledger',
      connectionExistsError:
        'The contact email is already in use by another supplier you are connected to.',
      inviteError: 'Failed to add supplier, please try again.',
      orgExists: 'The supplier with given name and location already exists',
    },
    addOnsRequired: {
      title: 'Add-ons required',
      none: 'None',
    },
    editAddOnsModal: {
      introTitle: 'Do you want the supplier to fill out any add-on domains?',
      introBody:
        'These add-on domains would be additional to the main set of controls. These domains are optional and should only be selected if they are relevant to the supplier you are onboarding',
      optionsTitle: 'Add-on domains',
      confirmTitle: 'Are you sure you want to make this change?',
      addTitle: 'Adding an add-on will:',
      add1: 'Notify the supplier to complete the control questions.',
      add2: "Immediately affect the supplier's compliance score, based on the add-on's controls, regardless of whether they have answered the questions.",
      removeTitle: 'Removing an add-on will:',
      remove1: 'Close any risks that are associated to the controls within this add-on.',
      remove2: 'Archive any discussions that are associated to the controls within this add-on.',
      remove3: 'Close any remediations that are associated to the controls within this add-on.',
    },
  },
};
