export default {
  en: {
    riskLedger: 'Risk Ledger',
    answerChangedGroup: '{name} changed the answer for {controls}',
    connectionComplianceChanged:
      'Overall Compliance changed from {prevCompliancePercentage} to {newCompliancePercentage}',
    connectionComplianceNoChange: 'Overall Compliance remains at {newCompliancePercentage}',
    answerChanged: '{name} changed {context} answer {prevValue} to {newValue}',
    answerChangedConnection:
      '{name} changed {context} answer {prevValue} to {newValue} for {control}',
    answerExpired: 'Answer has expired on {date}',
    answerExpiredConnection: 'Answer to {control} has expired on {date}',
    notesChanged: '{name} changed {context} answer notes',
    notesChangedConnection: '{name} changed {context} answer notes for {control}',
    evidenceAdded: '{name} added {fileName} as evidence',
    evidenceRemoved: '{name} removed {fileName} as evidence',
    evidenceRenamed: '{name} renamed {oldFileName} evidence to {newFileName}',
    evidenceAddedConnection: '{name} added {fileName} as evidence for {control}',
    evidenceRemovedConnection: '{name} removed {fileName} as evidence for {control}',
    evidenceReplaced: '{name} updated {fileName} evidence to a new version',
    evidenceReplacedConnection: '{name} updated {fileName} evidence to a new version for {control}',
    evidenceRenamedConnection:
      '{name} renamed {oldFileName} evidence to {newFileName} for {control}',
    policyRequirementChanged: 'Policy requirement changed from {prevValue} to {newValue}',
    policyRequirementChangedConnection:
      'Policy requirement changed from {prevValue} to {newValue} for {control}',
    policyRequirementChangedGroup:
      'Policy requirement changed for <b>{n} control</b> | Policy requirement changed for <b>{n} controls</b>',
    complianceChanged: 'Compliance changed from {prevCompliance} to {newCompliance}',
    complianceChangedConnection:
      'Compliance changed from {prevCompliance} to {newCompliance} for {control}',
    controlComplianceChangedGroup:
      'Compliance changed for <b>{n} control</b> | Compliance changed for <b>{n} controls</b>',
    overrideApplied: '{name} applied {overrideType} override',
    overrideRemoved: '{name} removed {overrideType} override',
    overrideAppliedGroup: '{name} applied {overrideType} to {controls}',
    overrideRemovedGroup: '{name} removed {overrideType} from {controls}',
    overrideAppliedConnection: '{name} applied {overrideType} override to {control}',
    overrideRemovedConnection: '{name} removed {overrideType} override from {control}',
    scopingAnswerChanged: '{name} changed {context} scoping answer {from} to {newValue}',
    scopingAnswerChangedConnection:
      '{name} changed {context} scoping answer to {newValue} for {domain}',
    scopingAnswerChangedGroup: '{name} changed the scoping answer for {domains}',
    domainScopingChanged: 'Control for {letter} became {newValue}',
    domainLetter: 'Domain {letter}',
    remediationRequested: '{name} requested a remediation',
    remediationResponded: '{name} responded to a remediation',
    remediationCancelled: '{name} cancelled a remediation',
    remediationCompleted: '{name} accepted a remediation',
    remediationRejected: '{name} rejected a remediation',
    remediationRequestedConnection: '{name} requested a remediation for {control}',
    remediationRespondedConnection: '{name} responded to a remediation for {control}',
    remediationCancelledConnection: '{name} cancelled a remediation for {control}',
    remediationCompletedConnection: '{name} accepted a remediation for {control}',
    remediationRejectedConnection: '{name} rejected a remediation for {control}',
    remediationRequestedGroup: '{name} requested the remediation of {control}',
    remediationCancelledGroup: '{name} cancelled the remediation of {control}',
    remediationRespondedGroup: '{name} responded to the remediation of {control}',
    remediationRejectedGroup: '{name} rejected the remediation of {control}',
    remediationCompletedGroup: '{name} accepted the remediation of {control}',
    remediationControlGroupCount: '{n} control | {n} controls',
    supplierAssessmentFrameworkUpdatedGroup: '{name} updated the Supplier Assessment Framework',
    approvalStatusChanged: '{name} changed {context} status {prevStatus} to {newStatus}',
    connectionCreated: 'Connection created between {client} and {supplier}',
    connectionRemoved: 'Connection removed between {client} and {supplier}',
    initialAssessmentCompleted: '{name} completed {context} assessment',
    reassessmentCompleted: '{name} completed {context} reassessment',
    tagChanged: '{name} changed {context} {tagType} tag from {prevTag} to {newTag}',
    connectionHid: 'hid',
    connectionUnhid: 'unhid',
    connectionPrivacyChanged: '{name} {action} this supplier from the network',
    controlUpdateConfirmed: '{name} confirmed their answer after the control was updated',
    controlUpdateConfirmedConnection: '{name} confirmed their answer after {control} was updated',
    controlCreated: '{name} introduced a new {control}',
    controlModified: '{name} modified the question for {control}',
    controlDeprecated: '{name} deprecated {control}',
    reapprovalExpiryConnectionConfig: {
      full: '{name} changed the {reapproval} timeframe {prevApprovalExpiryPeriod} to {newApprovalExpiryPeriod}',
      prev: 'from {prevApprovalExpiryPeriod}',
      reapproval: 'Re-Approval',
      reapprovalTimeframe: '{value} {unit}',
      months: 'month | months',
      years: 'year | years',
      disabled: 'Disabled',
    },
    userAssignmentGroup: {
      singleAssignmentType: '{name} {assignmentType} {users}',
      multipleAssignmentType: '{name} assigned {assignedUsers} and unassigned {unassignedUsers}',
      noChangeType: 'User assignment remains the same',
      assigned: 'assigned',
      unassigned: 'unassigned',
      user: '{user}, ',
      beforeLastUser: '{user} ',
      lastUser: 'and {user}',
    },
    userAssigned: {
      other: '{name} assigned {assignee}',
      self: '{name} self-assigned this supplier',
    },
    userUnassigned: {
      other: '{name} unassigned {assignee}',
      self: '{name} removed their assignment',
    },
    reassessmentStarted: '{name} started {context} reassessment',
    labelAssigned: {
      labelExists: '{name} added the {label} label',
      labelMissing: '{name} added a label that has since been deleted',
    },
    labelUnassigned: {
      labelExists: '{name} removed the {label} label',
      labelMissing: '{name} removed a label that was later deleted',
    },
    labelAssignmentGroup: {
      onlyAssigned: {
        one: '{name} assigned the {assignedLabels} label',
        many: '{name} assigned the {assignedLabels} labels',
      },
      onlyUnassigned: {
        one: '{name} unassigned the {unassignedLabels} label',
        many: '{name} unassigned the {unassignedLabels} labels',
      },
      bothTypes:
        '{name} assigned the {assignedLabels} and unassigned the {unassignedLabels} labels',
      noChange: 'Label assignment remains the same',
    },
    approvalReviewerAdded: {
      other: '{name} added {reviewer} as a reviewer',
      self: '{name} added themselves as a reviewer',
    },
    approvalReviewerResponded: {
      approve: '{name} approved {supplier}',
      reject: '{name} rejected {supplier}',
    },
    approvalReviewerRemoved: {
      other: '{name} removed {reviewer} as a reviewer',
      self: '{name} removed themselves as a reviewer',
    },
    approvalReviewerAddedGroup: {
      message: '{name} added {reviewers} as reviewers',
      separator: ', ',
      lastSeparator: ' and ',
    },
    approvalReviewerRemovedGroup: {
      message: '{name} removed {reviewers} as reviewers',
      separator: ', ',
      lastSeparator: ' and ',
    },
    associatedRiskOpened: {
      forThis: '{name} opened {risk}',
      forAssociation: '{name} opened {risk} for {association}',
      friendlyID: 'Risk-{number}',
      control: 'Control {ref}',
    },
    associatedRiskClosed: {
      forThis: '{name} closed {risk}',
      forAssociation: '{name} closed {risk} for {association}',
      friendlyID: 'Risk-{number}',
      control: 'Control {ref}',
    },
    associatedRiskReopened: {
      forThis: '{name} re-opened {risk}',
      forAssociation: '{name} re-opened {risk} for {association}',
      friendlyID: 'Risk-{number}',
      control: 'Control {ref}',
    },
    associatedRiskDeleted: {
      forThis: '{name} deleted {risk}',
      forAssociation: '{name} deleted {risk} for {association}',
      friendlyID: 'Risk-{number}',
      control: 'Control {ref}',
    },
    scoping: {
      outOfScope: 'out of scope',
      inScope: 'in scope',
    },
    context: {
      a: 'a',
      the: 'the',
      their: 'their',
      your: 'your',
      from: 'from',
      domainLetter: 'Domain {letter}',
    },
    answer: {
      no: 'No',
      yes: 'Yes',
      notRequired: 'Not Required',
      orMore: '{0} or more',
      orLess: '{0} or less',
      unanswered: 'Unanswered',
    },
    control: 'Control {letter}',
    controls: '{n} control | {n} controls',
    domain: 'Domain {letter}',
    domains: '{n} domain | {n} domains',
    override: {
      exemption: 'an exemption',
      nonCompliance: 'a non-compliance',
      exemptions: 'exemption overrides',
      nonCompliances: 'non-compliance overrides',
    },
    seeMore: 'See More Activities...',
    retry: 'Retry',
    failed: 'Failed fetching activity items',
    noneFound: 'No Activity Items Found',
    tag: {
      confidentiality: 'Confidentiality',
      criticality: 'Criticality',
      holdsPII: 'PII',
    },
  },
};
