<script lang="ts" setup>
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import { useLocalStorage } from '@vueuse/core';
import PublicFeatureFlagsMenu from '@/components/debug/PublicFeatureFlagsMenu.vue';
import { useCurrentUser, useCurrentUserPermissions } from '@/modules/currentUser/composables';
import { useCurrentOrg } from '@/modules/currentOrg/composables';
import NoPermissions from '@/modules/errors/NoPermissions.vue';
import { setUserProperties } from '@/libs/analytics';
import { useCurrentOrgRoles } from '@/modules/roles/composables';
import useHasPermissions from '@/composables/permissions';
import type Permission from '@/composables/permissions/permissions';

const { t } = useI18n();
const toast = useToast();

const store = useStore();
const { user } = useCurrentUser();
const { organisation } = useCurrentOrg();
watchEffect(() => {
  if (!user.value || !organisation.value) return;
  setUserProperties({
    status: store.state.assessment.status,
    user: user.value,
    org: organisation.value,
  });
});

// For persisting debug user role, we watch it from App.vue
// userRole is set from DebugMenuContent.vue
const { roles } = useCurrentOrgRoles();
const userRole = useLocalStorage<string | undefined>('debug-user-role', undefined);
const { override } = useCurrentUserPermissions();
watchEffect(() => {
  if (userRole.value) {
    const role = roles.value?.find((r) => r.id === userRole.value);
    if (role) {
      override(role.permissions);
    }
  }
});

const showPublicFlags = computed(
  () => import.meta.env.VUE_APP_PUBLIC_FLAGS_TOOL === 'true' && !user.value,
);

watch(
  () => store.state.legacy.api.api_error,
  (error) => {
    if (!error) return;
    // We don't want to show the toast for the tags error - it's handled inline
    if (error !== 'API_ERROR_TAGS') {
      toast.error(t(`errors.${error}`));
    }
    nextTick(() => {
      store.dispatch('legacy/clearGlobalError');
    });
  },
);

const {
  isReady,
  hasPermissions,
  hasClientPermissions,
  hasSupplierPermissions,
  hasFederatedPermissions,
} = useHasPermissions();
const route = useRoute();
const canAccess = computed(() => {
  if (!isReady.value) return true;

  // We do it like this so that parent routes get merged with children
  const permissions: Permission[] = [];
  let mode = '';
  for (const match of route.matched) {
    if (Array.isArray(match.meta.permissions)) {
      permissions.push(...match.meta.permissions);
    }
    if (typeof match.meta.mode === 'string') mode = match.meta.mode;
  }

  if (
    (mode === 'client' && !hasClientPermissions.value) ||
    (mode === 'supplier' && !hasSupplierPermissions.value) ||
    (mode === 'federated' && !hasFederatedPermissions.value)
  )
    return false;

  if (!permissions.length) return true;

  return hasPermissions(...(permissions as Permission[]));
});
</script>

<template>
  <router-view v-if="canAccess" v-slot="{ Component }">
    <PublicFeatureFlagsMenu v-if="showPublicFlags" class="fixed bottom-20 left-20 z-[9999]" />
    <!-- transition removed until query components have root nodes -->
    <!-- causes an issue when clicking sign up nbutton from shared profile page -->
    <!-- <transition name="fade" mode="out-in"> -->
    <component :is="Component" />
    <!-- </transition> -->
  </router-view>
  <NoPermissions v-else />
</template>
