export default {
  en: {
    landing: {
      recentSupplier: 'Recently Visited Suppliers',
      recentClient: 'Recently Visited Clients',
      recentEmpty: 'No recently viewed organisations',
      browseSuppliers: 'Browse All Suppliers',
      supplier: 'Supplier',
      client: 'Client',
      viewAllSuppliers: 'View All Suppliers',
      getFailed: 'Failed to get Quick Connect profiles',
    },
    filters: {
      connectionStatusConnected: 'Connected',
      connectionStatusNotConnected: 'Not Connected',
      orgTypeClient: 'Client',
      orgTypeSupplier: 'Supplier',
    },
    org: {
      managed: {
        title: 'RL Managed',
        description: 'This profile has been created and is managed by Risk Ledger.',
      },
      unclaimed: 'Unclaimed',
      trialRestricted: 'Trial restricted',
      trialRestrictedDescription: 'This profile is not included in your trial.',
    },
    results: {
      cantFindSupplier: "Can't find the supplier you're looking for?",
      noMatches: 'No matching results',
      tryDifferentSearch: 'Try searching for something else or change your filters.',
      createNewSupplier: 'Create new supplier {name}',
      createSupplier: 'Create Supplier',
      noResults: 'No results found',
      resultsCount: '{n} result | {n} results',
    },
    bar: {
      searchSupplier: 'Search for a supplier...',
      searchClient: 'Search for a client...',
      failed: 'Search failed',
    },
    nav: {
      title: 'Search',
      searchSuppliers: 'Search Suppliers',
      searchClients: 'Search Clients',
    },
  },
};
