<script lang="ts" setup>
import { useVModel } from '@vueuse/core';

defineOptions({ name: 'BaseSwitch' });

const props = withDefaults(
  defineProps<{
    modelValue?: boolean;
    disabled?: boolean;
    size?: 'small' | 'medium' | 'large';
    colour?: 'navy' | 'primary';
    label?: string;
    icon?: string;
  }>(),
  {
    size: 'medium',
    colour: 'navy',
    label: '',
    icon: undefined,
  },
);

const emit = defineEmits<{
  'update:modelValue': [value: boolean];
}>();

const value = useVModel(props, 'modelValue', emit);
</script>

<template>
  <label
    class="relative box-border inline-block cursor-pointer select-none"
    :class="{ 'cursor-not-allowed': disabled }"
    data-testid="base-switch"
  >
    <input
      v-model="value"
      type="checkbox"
      class="absolute h-0 w-0 opacity-0"
      data-testid="bs-checkbox"
      :disabled="disabled"
    />
    <div class="switch p-3" :class="[modelValue ? colour : 'bg-navy-300', size]">
      <div class="toggle bg-white" />
    </div>
    <span v-if="icon" class="ml-5" :class="{ 'text-disabled': disabled }">
      <BaseIcon :name="icon" />
    </span>
    <span v-if="label" :class="{ 'text-disabled': disabled }" class="label ml-5 font-bold">
      {{ label }}
    </span>
  </label>
</template>

<style scoped>
.switch {
  display: inline-block;
  width: 1.875em;
  height: 1em;
  vertical-align: middle;
  border-radius: theme('borderRadius.full');
  transition: all 50ms;

  &.small {
    font-size: theme('fontSize.sm');
  }

  &.medium {
    font-size: theme('fontSize.base');
  }

  &.large {
    font-size: theme('fontSize.lg');
  }
}

.primary {
  background-color: theme('colors.primary');
}

.navy {
  background-color: theme('colors.navy-500');
}

.toggle {
  width: 1em;
  height: 1em;
  border-radius: theme('borderRadius.full');
  transition: transform 50ms;
}

input[type='checkbox']:disabled + .switch {
  opacity: 0.5;
}

input[type='checkbox']:checked + .switch > * {
  transform: translateX(calc(0.875em));
}

.label {
  vertical-align: middle;
  transition: color 120ms ease 0s;
}
</style>
