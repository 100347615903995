import { CommunityPostTopic } from '@/types/models/communities';

export const iconsForTopic = {
  [CommunityPostTopic.General]: 'help',
  [CommunityPostTopic.Suppliers]: 'share',
  [CommunityPostTopic.IndustryChat]: 'chat',
  [CommunityPostTopic.BestPractices]: 'lightbulb',
  [CommunityPostTopic.EmergingThreats]: 'warning',
  [CommunityPostTopic.Events]: 'event',
} satisfies Omit<Record<CommunityPostTopic, string>, CommunityPostTopic.Activity>;

export const insideRollingWindow = (
  groupPostCreatedAt: string,
  postCreatedAt: string,
  windowMinutes: number,
): boolean => {
  const groupDate = Date.parse(groupPostCreatedAt);
  const postDate = Date.parse(postCreatedAt);
  return Math.abs(groupDate - postDate) < windowMinutes * 60000;
};

/**
 * This function reduces the height of a specified element to fit within a
 * specified max height by hiding elements and removing words until the text
 * fits within the height.
 *
 * Careful - it modifies the element passed to it. Use with v-once so that Vue
 * doesn't try to add the text back.
 */
export function truncateTextElement(el: Element, readMoreText: string, maxHeight = 40) {
  let lastChild = el.children[0];
  let numberOfElements = 0;
  let heightSoFar = 0;
  do {
    numberOfElements++;
    lastChild = el.children[numberOfElements - 1];
    heightSoFar += lastChild.getBoundingClientRect().height;
  } while (heightSoFar < maxHeight && numberOfElements < el.children.length);

  // Nothing to hide!
  if (heightSoFar <= maxHeight && numberOfElements === el.children.length) return;

  // Hide extra paragraphs
  for (let i = numberOfElements; i < el.children.length; i++) {
    el.children[i].classList.add('hidden');
  }

  const maxHeightLastChild = lastChild.getBoundingClientRect().height - (heightSoFar - maxHeight);
  const readMoreLink = document.createElement('a');
  readMoreLink.dataset.keep = 'true';
  readMoreLink.innerHTML = readMoreText;
  lastChild.appendChild(readMoreLink);

  // Remove words until last element small enough
  const lastChildChildren = lastChild.childNodes;
  function removeWord(removeFromNode: Node, addEllipsis = false) {
    if (removeFromNode instanceof Text) {
      const text = removeFromNode.textContent || '';
      const lastSpace = text.lastIndexOf(' ', text.length - 2);
      if (lastSpace !== -1 && lastSpace !== 0) {
        removeFromNode.textContent = `${text.slice(0, lastSpace)}${addEllipsis ? '… ' : ''}`;
      } else {
        removeFromNode.remove();
      }
    } else if (removeFromNode instanceof HTMLElement) {
      removeWord(removeFromNode.childNodes[removeFromNode.childNodes.length - 1]);
      if (!removeFromNode.textContent?.trim()) {
        removeFromNode.remove();
      }
    }
  }

  // Add ellipsis here in case removeWord isn't called - in the case of there
  // only being one line of text
  function addEllipsis(node: Node) {
    if (node instanceof Text) {
      node.textContent += '… ';
    } else if (node instanceof HTMLElement) {
      let lastChild = node.childNodes[node.childNodes.length - 1];
      if (lastChild instanceof HTMLElement && lastChild.dataset.keep) {
        lastChild = node.childNodes[node.childNodes.length - 2];
      }
      addEllipsis(lastChild);
    }
  }
  addEllipsis(lastChild);

  let i = 1000; // Infinite loop protection
  while (lastChild.getBoundingClientRect().height > maxHeightLastChild && i--) {
    removeWord(lastChildChildren[lastChildChildren.length - 2], true);
  }
}
