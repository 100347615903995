import { createApp } from 'vue';
import { createPinia } from 'pinia';

import RiskLedgerPlugin from '@/plugins/risk-ledger';
import { logError } from '@/libs/analytics';

import store from '@/store';
import router from '@/routes';
import App from './App.vue';

import '@/styles/all';

const app = createApp(App);

app.use(createPinia());

app.use(router);

app.use(store);

app.use(RiskLedgerPlugin);

app.config.errorHandler = (error: unknown) => {
  if (import.meta.env.MODE !== 'prod') {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  logError(error);
};

router.isReady().then(() => {
  app.mount('#app');
});
