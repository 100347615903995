import { request } from '@/api/request';
import type { Connection } from '@/types/models/connection';
import type { ReassessmentStatus } from '@/types/models/reassessment';
import type { FrameworkConfig } from '@/modules/framework/api';

// TODO: use enum from profile models file
export enum ProfileType {
  CLAIMED_SUPPLIER = 'CLAIMED_SUPPLIER',
  UNCLAIMED_SUPPLIER = 'UNCLAIMED_SUPPLIER',
  CLIENT = 'CLIENT',
}

export enum ConnectionProfileType {
  UNCLAIMED_CONNECTION = 'UNCLAIMED_CONNECTION',
  BASIC_CONNECTION = 'BASIC_CONNECTION',
  FULL_CONNECTION = 'FULL_CONNECTION',
  MANAGED_CONNECTION = 'MANAGED_CONNECTION',
}

export type CommunityStats = {
  totalConnections: number;
  approvals: number;
  risks: {
    open: number;
    closed: number;
  };
  controlSignals?: number;
};

export type Stats = {
  compliance: number;
  complianceByControl: {
    compliant: number;
    nonCompliant: number;
  };
  complianceByDomain: {
    [key: string]: {
      numberNoncompliant: number;
      percentageCompliant: number;
    };
  };
  exemptions: number;
  nonCompliances: number;
  remediationResponses: number;
  remediations: number;
  community?: ({
    communityID: string;
  } & CommunityStats)[];
  allCommunities?: CommunityStats;
};

export type SupplierInfoResponse = {
  connectionID: string;
  orgInfo: {
    companyNumber: string;
    country: string;
    id: string;
    logoUrl: string;
    name: string;
    sector: string;
    tradingNames: string[];
  } | null;
  stats: Stats | null;
  policiesApplied: {
    id: string;
    name: string;
  }[];
  tagsApplied: {
    confidentiality: string;
    criticality: string;
    pii: string;
  };
  initialAssessmentComplete: boolean;
  assessmentProgress: number;
  lastActiveAt: string | null;
  managed: boolean;
  profiles: string[] | undefined;
  connectionProfileType: string | undefined;
  assignedUsers: string[];
  isPrivate: boolean;
};
const getSupplierInfo = (id: string) => request<SupplierInfoResponse>('GET', `/supplier/${id}`);

export type SupplierPublicInfoResponse = {
  id: string;
  name: string;
  companyNumber: string;
  country: string;
  streetAddress: string;
  postcode: string;
  city: string;
  sector: string;
  tradingNames: string[];
  website: string;
  profiles: string[] | undefined;
  connectionProfileType: string | null | undefined;
  managed: boolean;
  isPrivate: boolean;
};

const getPublicSupplierInfo = (id: string) =>
  request<SupplierPublicInfoResponse>('GET', `/supplier/${id}/info`);

const getSupplierAssessment = (id: string) => request('GET', `/supplier/${id}/assessment`);

const getSupplierReassessmentStatus = (supplierID: string) =>
  request<ReassessmentStatus>('GET', `/reassessment/status/${supplierID}`);

const removeConnection = (id: string, removedMessage: string) =>
  request<Connection>('DELETE', `/org/connections/${id}`, {
    removedMessage,
  });

const setSupplierTags = (id, tags) => request('PUT', `/supplier/${id}/tagsapplied`, tags);

export type SetAssignedUsersResponse = {
  assignedUsers: string[];
  createdAt: string;
};
const setAssignedUsers = (connID: string, users: string[]) =>
  request<SetAssignedUsersResponse>('PUT', `/supplier/${connID}/userassignments`, {
    users,
  });

const getSupplierEvidence = (id) => request('GET', `/suppliers/${id}/evidences`);

export type ToggleSupplierPrivacyResponse = {
  isPrivate: boolean;
  toggledPrivateAt: string;
  toggledPrivateBy: string;
};

const toggleSupplierPrivacy = (id: string) =>
  request<ToggleSupplierPrivacyResponse>('POST', `/suppliers/${id}/togglePrivacy`);

const getFrameworkConfig = (connectionID: string) =>
  request<FrameworkConfig>('GET', `/framework/config/connection/${connectionID}`);

const setFrameworkConfig = (connectionID: string, config: FrameworkConfig) =>
  request<FrameworkConfig>('POST', `/framework/config/connection/${connectionID}`, config);

export default {
  getSupplierInfo,
  getSupplierAssessment,
  getSupplierReassessmentStatus,
  removeConnection,
  setSupplierTags,
  setAssignedUsers,
  getSupplierEvidence,
  getPublicSupplierInfo,
  toggleSupplierPrivacy,
  getFrameworkConfig,
  setFrameworkConfig,
};
