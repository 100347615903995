import { useMutation, useQuery, type MutationObserverOptions } from '@tanstack/vue-query';
import type { AxiosError } from 'axios';
import { useStore } from 'vuex';
import { mergeMutationOptions, mergeQueryOptions, type QueryOptions } from '@/modules/query/utils';
import type { Answer } from '@/types/models/answer';
import type { FrameworkConfig } from '@/modules/framework/api';
import { FRAMEWORK_DEFAULT_CONFIG_QUERY, useFramework } from '@/modules/framework/composables';
import api from './api';

export const ASSESSMENT_ANSWERS_QUERY = '/assessment/answers';

export type ControlAnswersResponse = Record<string, Answer>;

export function useControlAnswers(options?: QueryOptions<ControlAnswersResponse>) {
  const query = useQuery(
    mergeQueryOptions(options, {
      queryKey: [ASSESSMENT_ANSWERS_QUERY],
      async queryFn() {
        const res = await api.getControlAnswers();
        return res.data;
      },
    }),
  );

  function getByAnswerID(answerID: string) {
    if (!query.data.value) return null;

    for (const controlAnswer of Object.values(query.data.value)) {
      if (controlAnswer.answerID === answerID) {
        return controlAnswer;
      }
    }

    return null;
  }

  return { ...query, getByAnswerID, controlAnswers: computed(() => query.data.value || {}) };
}

export function useScopingComplete(
  options?: MutationObserverOptions<void, AxiosError, void, null>,
) {
  const store = useStore();

  return useMutation(
    mergeMutationOptions(options, {
      async mutationFn() {
        await api.completeScopingQuestions();
      },
      async onSuccess(_data) {
        trackEvent('scoping:completed');
        // Revalidate assessment status
        await store.dispatch('assessment/getAssessmentStatus');
      },
    }),
  );
}

export const NEW_IN_SCOPE_DOMAINS_QUERY = '/quickanswer/newInScopeDomains';
export function useNewInscopeDomains(options?: QueryOptions<string[]>) {
  const query = useQuery(
    mergeQueryOptions(options, {
      queryKey: [NEW_IN_SCOPE_DOMAINS_QUERY],
      async queryFn() {
        const res = await api.getNewInscopeDomains();
        return res.data;
      },
    }),
  );

  return { ...query, newInscopeDomains: computed(() => query.data.value || []) };
}

export const COMBINED_FRAMEWORK_CONFIG_QUERY = '/framework/config';
export function useCombinedFrameworkConfig(options?: QueryOptions<FrameworkConfig>) {
  const query = useQuery(
    mergeQueryOptions(options, {
      queryKey: [FRAMEWORK_DEFAULT_CONFIG_QUERY],
      async queryFn() {
        const res = await api.getCombinedFrameworkConfig();
        return res.data;
      },
    }),
  );

  const addOnDomains = computed(() => query.data.value?.addOnDomains || []);

  const { sortedControls, sortedDomains } = useFramework();

  // All domain IDs from domains that are required by the framework config.
  const frameworkRequiredDomainIDs = computed(() =>
    sortedDomains.value
      .filter((d) => !d.isAddOn || addOnDomains.value.includes(d.domainID))
      .map((d) => d.domainID),
  );

  const frameworkRequiredControlIDs = computed(() =>
    sortedControls.value
      .filter((c) => frameworkRequiredDomainIDs.value.includes(c.domainID))
      .map((c) => c.controlID),
  );

  return { ...query, addOnDomains, frameworkRequiredDomainIDs, frameworkRequiredControlIDs };
}
