import { request } from '@/api/request';

// TODO: we will hopefully refactor endpoints and
// get rid of the /inbox postfix in the urls in the future
const getDiscussionPreviews = ({ role, participationType, archived, orgFilter, page, limit }) =>
  request('POST', '/discussions/inbox', {
    role,
    participationType,
    archived,
    orgFilter,
    page,
    limit,
  });

const getDiscussion = (id) => request('GET', `/discussions/${id}/inbox`);

const startDiscussion = (
  associatedID,
  associatedType,
  message,
  clientID,
  supplierID,
  attachments,
) =>
  request('POST', `/discussion`, {
    associatedID,
    associatedType,
    message,
    clientID,
    supplierID,
    attachments,
  });

const replyDiscussion = (discussionID, message, attachments) =>
  request('POST', `/discussion/${discussionID}`, {
    message,
    attachments,
  });

const getDiscussionsByAssociatedID = (associatedID) =>
  request('GET', `/discussions/associated/${associatedID}`);

const archiveDiscussion = (discussionID) => request('POST', `/discussion/${discussionID}/archive`);

const unarchiveDiscussion = (discussionID) =>
  request('POST', `/discussion/${discussionID}/unarchive`);

const markCommentsAsSeen = (discussionId, commentIds) =>
  request('POST', `/discussions/${discussionId}/markCommentsAsSeen`, { commentIds });

export default {
  getDiscussionPreviews,
  getDiscussion,
  getDiscussionsByAssociatedID,
  startDiscussion,
  replyDiscussion,
  archiveDiscussion,
  unarchiveDiscussion,
  markCommentsAsSeen,
};
