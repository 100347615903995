export default {
  en: {
    pageTitle: 'Policies',
    empty: {
      title: 'No Policies',
      subtitle: 'Add a policy to apply requirements to suppliers',
    },
    addEditPage: {
      saveAdd: 'Save Policy',
      saveEdit: 'Save Changes',
      learnMore: 'Learn More',
      errors: {
        alreadyExists: "There's already a Policy with that name.",
        loading: 'There was an error loading the Policy.',
        saving: 'There was an error when trying to save the Policy.',
        emptyName: 'The Policy must have a name.',
        emptyTagsLabels: 'The Policy must include at least 1 tag or label to apply to.',
        emptyMultiselect: 'The Policy has required fields that are empty.',
      },
    },
    controlRow: {
      new: 'New',
      updated: 'Updated',
      newTooltip: 'Risk Ledger recently introduced this control',
      updatedTooltip: 'Risk Ledger recently updated this control',
    },
    row: {
      lastModified: 'Last modified by {0} on {1}',
      appliesToSuppliers: '{count} Supplier | {count} Suppliers',
      supplierList: '{0} and {1} more ...',
      edit: 'Edit',
      delete: 'Delete',
      duplicate: 'Duplicate',
      modal: {
        title: 'Are you sure you want to delete the policy "{0}"?',
        description:
          "This policy's requirements will stop being applied to {count} supplier | This policy's requirements will stop being applied to {count} suppliers",
        confirm: 'Delete',
        cancel: 'Cancel',
        banner: 'There was a problem while trying to delete the policy',
      },
    },
    controlTypes: {
      nocompliant: 'Require No',
      yescompliant: 'Require Yes',
      atleast: 'Require at least',
      atmax: 'Require at most',
    },
    filters: {
      title: 'Filter by...',
      placeholder: 'Search by control name...',
      required: 'Required',
      notRequired: 'Not Required',
    },
    form: {
      name: 'Name',
      applies_to: 'Applies To',
      notes: 'Notes',
      placeholders: {
        name: 'Enter name...',
        applies_to: 'Select tags...',
        applies_to_tags_labels: 'Select tags and labels...',
        notes: 'Enter notes...',
      },
    },
    controlList: {
      noFilteredAnswers: 'No controls match the filters applied',
      noFilteredAnswersNote: 'Change the filters to show some controls',
    },
    listPage: {
      title: 'Policies',
      addPolicy: 'Add Policy',
      messageTitle: 'Customise Your Supplier Policies',
      messageSubtitle: `Improve your supply chain with our Risk Ledger templates, designed to match industry standards. You can adjust these policies to fit your needs or create new ones for better compliance control.
        <br>Remember, these policies affect compliance scores, not the assessment questions. Need more info?`,
      linkText: 'Learn more about policies and compliance scores',
      error: 'Failed to load policies. Please try again or contact Risk Ledger support.',
    },
    tagLabelSelector: {
      tags: 'Tags',
      labels: 'Labels',
      newLabel: 'Create new label',
    },
    policyMatrix: {
      title: 'Policy Matrix',
      policiesRoot: 'Policies',
      titleFormat: '{root} / {node}',
      export: 'Export',
      domainColumn: 'Domain',
      controlColumn: 'Control',
      empty: 'You have no policies',
      filename: 'riskledger-policy-matrix',
    },
  },
};
